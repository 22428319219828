<template>
  <v-container fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="10">
        <v-row>
          <v-col cols="12" sm="6" align-self="center">
            <page-top-title>
              <template v-slot:toptitle-icon>mdi-dna</template>
              <template v-slot:toptitle-text>Genotyper Keys</template>
            </page-top-title>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
     <v-layout child-flex>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="genotyperKeys"
          :search="search"
          sort-by="key"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Nueva Genotyper Key
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.key"
                            label="Clave"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.data_matrix_keys"
                            label="Data Matrix Keys"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-textarea
                            v-model="editedItem.values"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-data-table
                            class="elevation-1"
                            :headers="detailTableHeaders"
                            :items="detailTableData"
                          ></v-data-table>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >¿Eliminar la clave?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import PageTopTitle from "@/components/mylogy/PageTopTitle";

export default {
  name: "GenotyperKeys",
  components: { PageTopTitle },
  data: () => ({
    search: "",
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Clave",
        align: "start",
        sortable: true,
        value: "key"
      },
      { text: "Acciones", value: "actions", sortable: false }
    ],
    genotyperKeys: [],
    editedIndex: -1,
    editedItem: {
      key: null,
      values: "",
      data_matrix_keys: "",
      _id: null
    },
    defaultItem: {
      key: null,
      values: "",
      data_matrix_keys: ""
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva Clave" : "Editar Clave";
    },
    detailHeaders() {
      return ["RESULT"].concat(
        this.editedItem.data_matrix_keys
          .replace(/ /g, "")
          .split(",")
          .filter(s => {
            return s.length > 0;
          })
      ).concat(["HAPLOTYPE", "FREQUENCY"])
    },
    detailTableHeaders() {
      return this.detailHeaders.map(h => {
        return {
          text: h,
          sortable: true,
          value: h
        };
      });
    },
    detailTableData() {
      let self = this;
      return self.editedItem.values
        .split("\n")
        .filter(s => {
          return s.length > 0;
        })
        .map(row => {
          let rowObj = {};
          self.detailHeaders
            .map((c, idx) => {
              return [[c], row.split("|")[idx]!== undefined && row.split("|")[idx] || ""];
            })
            .forEach(r => {
              rowObj[r[0]] = r[1];
            });
          return rowObj;
        });
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      let self = this;
      fetch(
        `https://strapi.mylogy.xyz/genotyper-keys?token=zyroivKXeWFPxWf9bMXZBbaKCc9fwCprxuAcqKeEt3xZb8g9&_limit=1000`
      ).then(function(response) {
        response.json().then(function(data) {
          self.genotyperKeys = data;
        });
      });
    },

    editItem(item) {
      let self = this
      self.editedItem = Object.assign({}, item);
      console.log(self.editedItem)
      self.editedIndex = self.genotyperKeys.indexOf(item);
      var currentValues = ""
      item.values.forEach((row) => {
        currentValues += self.detailHeaders.map((h) => {
          return row[h] !== undefined && row[h] || ""
        }).join("|")+"\n"
      })
      self.editedItem.values = currentValues
      
      self.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.genotyperKeys.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let self = this;
      fetch(
        `https://strapi.mylogy.xyz/data-matrix-keys/${self.editedItem._id}?token=zyroivKXeWFPxWf9bMXZBbaKCc9fwCprxuAcqKeEt3xZb8g9`,
        {
          method: "DELETE"
        }
      ).then(function(response) {
        response.json().then(function() {
          self.genotyperKeys.splice(self.editedIndex, 1);
          self.closeDelete();
        });
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      /*
      let self = this;
      if (self.editedIndex > -1) {
        fetch(
          `https://strapi.mylogy.xyz/data-matrix-keys/${self.editedItem._id}?token=zyroivKXeWFPxWf9bMXZBbaKCc9fwCprxuAcqKeEt3xZb8g9`,
          {
            method: "PUT",
            body: JSON.stringify(self.editedItem),
            headers: { "Content-type": "application/json; charset=UTF-8" }
          }
        ).then(function(response) {
          response.json().then(function(data) {
            Object.assign(self.matrixKeys[self.editedIndex], data);
            self.close();
          });
        });
      } else {
        fetch(
          `https://strapi.mylogy.xyz/data-matrix-keys?token=zyroivKXeWFPxWf9bMXZBbaKCc9fwCprxuAcqKeEt3xZb8g9`,
          {
            method: "POST",
            body: JSON.stringify(self.editedItem),
            headers: { "Content-type": "application/json; charset=UTF-8" }
          }
        ).then(function(response) {
          response.json().then(function(data) {
            self.matrixKeys.push(data);
            self.close();
          });
        });
      }
      */
    }
  }
};
</script>
